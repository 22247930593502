










import { Component, Vue } from "vue-property-decorator";
import RolesManagement from "@/components/Roles/RolesManagement.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";

@Component({
  components: { RolesManagement, PageTemplate },
})
export default class Roles extends Vue {
  RouteName = RouteName;
}
