var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"page-row",staticStyle:{"align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mr-2 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Assign a role to a user"))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-help-circle-outline")])],1)]}}])},[_c('span',[_c('p',{staticClass:"font-weight-medium mb-0"},[_vm._v("Admin")]),_c('ul',[_c('li',[_vm._v("In-line moderation")]),_c('li',[_vm._v("Sub-channel management")]),_c('li',[_vm._v("Poll management")])])]),_c('span',[_c('p',{staticClass:"font-weight-medium mb-0"},[_vm._v("Moderator")]),_c('ul',[_c('li',[_vm._v("In-line moderation")]),_c('li',[_vm._v("Sub-channel management")])])])])],1)]),_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"flex-grow-1",staticStyle:{"max-width":"350px"}},[_c('v-autocomplete',{attrs:{"items":_vm.userRoleList.filter(
                function (user) { return user.editRequest && user.editRequest.displayName; }
              ),"item-text":'editRequest.displayName',"item-value":'id',"dense":"","filled":"","clearable":"","placeholder":_vm.$t('Add users by user-name or user-id'),"background-color":"background"},model:{value:(_vm.selectedUserId),callback:function ($$v) {_vm.selectedUserId=$$v},expression:"selectedUserId"}})],1),_c('v-col',{staticClass:"flex-grow-1",staticStyle:{"max-width":"450px"}},[_c('v-select',{staticClass:"mt-0 pt-0",attrs:{"items":_vm.roleItems,"item-text":"label","placeholder":_vm.$t('Select a role'),"multiple":"","color":"brand","item-color":"brand"},model:{value:(_vm.userRole),callback:function ($$v) {_vm.userRole=$$v},expression:"userRole"}})],1),_c('v-col',{staticClass:"flex-grow-0 ml-auto"},[_c('v-btn',{staticClass:"px-8 brand secondary--text text-uppercase",class:_vm.disabled ? 'btn-disabled' : null,attrs:{"color":"brand"},on:{"click":_vm.addUser}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])],1)],1)],1)],1),_c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usersWithRoles,"search":_vm.searchTable,"item-key":"item","footer-props":{
          'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Currently assigned roles"))+" ("+_vm._s(_vm.usersWithRoles.length)+") ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.searchTable),callback:function ($$v) {_vm.searchTable=$$v},expression:"searchTable"}})],1)]},proxy:true},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [(!_vm.editUser || _vm.editUser.id !== item.id)?[_c('div',[_vm._v(" "+_vm._s(item.roles.map(function (role) { return _vm.getRoleLabel(role); }).join(", "))+" ")])]:[_c('v-select',{staticClass:"mt-0 pt-0",staticStyle:{"max-width":"450px"},attrs:{"items":_vm.roleItems,"item-text":"label","placeholder":_vm.$t('Select a role'),"multiple":"","color":"brand","item-color":"brand","hide-details":""},model:{value:(_vm.editUser.roles),callback:function ($$v) {_vm.$set(_vm.editUser, "roles", $$v)},expression:"editUser.roles"}})]]}},{key:"item.lastModified",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.lastModifiedDate(item)))])]}},{key:"item.row-actions",fn:function(ref){
        var item = ref.item;
return [(!_vm.editUser || _vm.editUser.id !== item.id)?[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","disabled":_vm.saving},on:{"click":function($event){return _vm.setEditUser(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]:[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","disabled":_vm.saveEditUserDisabled},on:{"click":_vm.updateUserRoles}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.saving},on:{"click":function($event){_vm.editUser = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }