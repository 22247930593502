






































































































































































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import copy from "fast-copy";
import { EditRoleRequest, UserRole } from "./roles/types";
import { getRoleLabel, getRoleSelectionItems } from "@/helpers";
import { Role, User } from "@/spect8-core-vue/src/types";

@Component({
  components: {},
})
export default class RolesManagement extends Vue {
  @Getter("roleUser/userRoleList")
  readonly userRoleList!: UserRole[];
  @Action("roleUser/getUserRoleList")
  getUserRoleList!: () => void;
  @Action("roleUser/editRole")
  saveRoles!: (data: EditRoleRequest) => Promise<void>;

  component = "RolesManagement";
  defaultSelect = "admin";

  // New User
  selectedUserId = "";
  userRole: Role[] = [];

  //Edit User
  editUserRef: User | null = null;
  editUser: User | null = null;
  saving = false;

  member = "";
  userId = "";
  search = null;
  searchTable = "";
  roleItems = getRoleSelectionItems([Role.ADMIN, Role.MODERATOR]);

  headers = [
    {
      text: this.$t("Username"),
      value: "editRequest.displayName",
      width: "30%",
    },
    { text: this.$t("Roles"), value: "role", width: "35%" },
    {
      text: this.$t("Last modified"),
      value: "lastModified",
      class: "flex-grow-1",
    },
    {
      text: this.$t("dataTable.actions"),
      value: "row-actions",
      align: "center",
      cellClass: "text-no-wrap",
    },
  ];

  getRoleLabel = getRoleLabel;

  get usersWithRoles(): UserRole[] {
    return this.userRoleList.filter((user) => {
      return (
        user.roles?.length &&
        user.roles.some((role) => [Role.ADMIN, Role.MODERATOR].includes(role))
      );
    });
  }

  get disabled() {
    if (this.selectedUserId && this.userRole.length) return false;
    return true;
  }

  lastModifiedDate(value: UserRole) {
    //this is last modified date from profile?
    return moment(value.lastModified).format("YYYY/MM/DD");
  }

  created() {
    this.getUserRoleList();
  }

  setEditUser(user: User) {
    this.editUserRef = user;
    this.editUser = copy(user);
  }

  async updateUserRoles() {
    if (!this.editUser || !this.editUserRef) return;

    this.saving = true;

    await this.saveRoles({
      userId: this.editUser.id,
      role: this.editUser.roles,
    });

    // Update user in user list by ref. ( This is only for the SFC state )
    this.editUserRef.roles = this.editUser.roles;
    this.saving = false;
    this.editUser = null;
    this.editUserRef = null;
  }

  async addUser() {
    if (!this.selectedUserId) return;

    await this.saveRoles({
      userId: this.selectedUserId,
      role: this.userRole,
    } as EditRoleRequest);

    this.selectedUserId = "";
    this.userRole = [];
  }

  get saveEditUserDisabled(): boolean {
    if (this.saving) return true;

    if (this.editUserRef && this.editUser) {
      const editUserRef: User = this.editUserRef;

      if (
        this.editUserRef.roles.length !== this.editUser.roles.length ||
        this.editUser.roles.some((role) => !editUserRef.roles.includes(role))
      ) {
        return false;
      }
    }

    return true;
  }
}
